import React from 'react';
import { Card, Typography } from 'antd';
import './CardComponent.scss';
import {NavLink} from 'react-router-dom';
import ReactGA from 'react-ga';

const Title = Typography;

type Props = {
    data: any
}

export const CardComponent: React.FC <Props> = ({data}) => {

    const gaEvent = (category: string, action: string) => {
        ReactGA.event({
            category: category,
            action: action
        });
    };

    return (
        <Card className="global-card">
            <NavLink onClick={() => {gaEvent(`Card ${data.title} selected`, 'Show description')}} style={{
                color: 'black',
                height: '100%',
                width: '100%',
                display: "flex",
                justifyContent: 'center',
                flexDirection: 'column'
            }} to={{
                pathname: "/description",
                state: {data}
            }} location={data}>
            <span className="header-card">
            <img src={require(`../../assets/${data.logo}`)} alt="Logo"/>
            <Title className="header-card-title">{data.title}</Title>
        </span>
                <div className="content-card">
                    <Title className="content-card-title">Description :</Title>
                    <p>{data.descriptionCard}</p>
                </div>
            </NavLink>
        </Card>
    );
}

