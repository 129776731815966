import React, {useState, useEffect} from 'react';
import { Row, Input, Select } from 'antd';
import {Header} from "../components/header/Header";
import './Home.scss';
import {CardComponent} from "../components/cards/CardComponent";
import {CardInfo} from "../components/cards/CardInfo";
import data from '../data/data.json';
import category from '../data/category.json';

const { Option } = Select;

export const Home: React.FC = () => {

    const [contentCard, setContentCard] = useState<Array<object>>(data);
    const [saveCategory, setSaveCategory] = useState<Array<any>>(data);
    const [searchingWord, setSearchingWord] = useState <string>('');
    const keyCategory = Object.keys(category);

    useEffect(() => {
        let newData: any[] = [];
        if (searchingWord) {
            for (let i = 0; i < saveCategory.length; i++) {
                let arraySentence = saveCategory[i].description.split(' ').concat(saveCategory[i].descriptionCard.split(' '))
                arraySentence.push(saveCategory[i].title)
                for (let y = 0; y < arraySentence.length; y++) {
                    // @ts-ignore
                    if (arraySentence[y].toLowerCase().includes(searchingWord)) {
                        if (!newData.includes(saveCategory[i])){
                            newData.push(saveCategory[i])
                        }
                    }
                }
            }
            setContentCard(newData)
        } else {
            setContentCard(saveCategory)
        }

    }, [searchingWord, saveCategory])

    const searchingCategory = (word: string) => {
        let result = [];
        // @ts-ignore
        let arrayId = category[word];
        console.log(arrayId)
        for (let i = 0; i < arrayId.length; i++) {
            for (let j = 0; j < data.length; j++) {
                if (data[j].id === arrayId[i]) {
                    result.push(data[j])
                    console.log(data[j])
                }
            }
        }

        setContentCard(result);
        setSaveCategory(result)
    }

    return (
        <div className="global-ui">
            <div className="background"></div>
            <Row className="dashboard">
                <div className="header-container">
                    <Header />
                </div>
                <div className="select-container">
                    <Select onSelect={(value) => searchingCategory(value)} defaultValue={keyCategory[0]} size="large">
                        {
                            keyCategory.map((category: string) => <Option className="category-key" value={category}>{category}</Option>)
                        }
                    </Select>
                    <Input  
                        placeholder="Rechercher"
                        size="large"
                        onChange={event => {
                            setSearchingWord(event.target.value)
                        }}
                    />
                </div>
                <div className="card-container">
                    <CardInfo />
                    {
                        contentCard ? contentCard.map((data: any) => <CardComponent data={data} />) : null
                    }
                </div>
            </Row>
        </div>
    );
};
