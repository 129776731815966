import React, {useState, useEffect} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {Row, Typography, Button} from 'antd';
import {Header} from "../header/Header";
import './Description.scss';
import ReactGA from 'react-ga';

const Title = Typography;


export const Description: React.FC = () => {
    const location = useLocation();
    const history = useHistory();
    const [data, setData] = useState();

    useEffect( () => {
        if (location.state === undefined) {
            history.push('/home')
        } else {
            // @ts-ignore
            let {data} = location.state
            setData(data)
        }
    }, [location.state, history]);

    const gaEvent = (category: string, action: string) => {
        ReactGA.event({
            category: category,
            action: action
        });
    };

    return (
        <div className="global-ui-description">
            <div className="background"/>
            { data ? <Row className="dashboard">
                <div className="header-container">
                    <Header/>
                </div>
                {/*<div className="back-button-container">
                     <Button className="back-button"><NavLink to={'/'}>Retour</NavLink></Button>
                 </div>*/}

                <div className="container-description">
                    <div className="description-image">
                        <img onClick={() => {
                            window.location = data.link_app;
                            gaEvent(`Action in card ${data.title}`, 'Go to app');
                        }} src={require(`../../assets/${data.screenShot}`)} alt={`${data.screenShot.split('.')[0]}`}/>
                    </div>
                    <div className='content-description'>
                        <span className='header-description'>
                            <img src={require(`../../assets/${data.logo}`)} alt={`${data.logo.split('.')[0]}`}/>
                            <Title className="title-app">{data.title}</Title>
                        </span>
                        <div className="description-text">
                            <p>{
                                data.description ? data.description : null
                            }</p>
                            <span className="global-container-button">
                                <span className="container-button">
                                    <Title className="content-button" style={{color: 'white'}}>L'application :</Title>
                                    <Button className="content-button" onClick={() => {
                                        window.location = data.link_app;
                                        gaEvent(`Action in card ${data.title}`, 'Go to app');
                                    }}>Accéder</Button>
                                </span>
                                <span className="container-button">
                                    <Title className="content-button"
                                           style={{color: 'white'}}>La channel teams :</Title>
                                    <Button className="content-button" onClick={() => {
                                        window.location = data.link_teams;
                                        gaEvent(`Action in card ${data.title}`, 'Go to channel');
                                    }}>Accéder</Button>
                                </span>
                            </span>
                            {
                                data.apple_store ?
                                    <div className="content-store">
                                        <img className="logo-store" onClick={() => {
                                            window.location = data.google_play;
                                            gaEvent(`Action in card ${data.title}`, 'Go to google-play');
                                        }} src={require("../../assets/google-play.svg")} alt="google-play"/>
                                        <img className="logo-store" onClick={() => {
                                            window.location = data.apple_store;
                                            gaEvent(`Action in card ${data.title}`, 'Go to app-store');
                                        }} src={require("../../assets/app-store.svg")} alt="app-store"/>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </Row> : null}
        </div>
    );
};
