import React from 'react';
import {Card, Typography, Button} from "antd";
import './CardInfo.scss'

const Title = Typography;

export const CardInfo: React.FC = () => {
    const urlTeams = "https://teams.microsoft.com/l/channel/19%3ab17fe6005ef549dc8b91a790bf69ffaf%40thread.skype/G%25C3%25A9n%25C3%25A9ral?groupId=4ee8dd14-d949-4968-8743-2143723ad178&tenantId=12cab382-e304-4d64-99b1-ed9db5e85ba4";
    return (
        <Card className="global-card-info">
         <span className="header-card-info">
            <img src={require('../../assets/Picto_A-propos_AltyorHub.svg')} alt="Logo"/>
            <Title className="header-info-title">A propos</Title>
        </span>
            <span className="content-card-info">
            <p>
                Si vous rencontrez un problème avec les applications proposées,
                ou bien si vous avez des suggestions n'hésitez pas à nous rejoindre
                dans les channels de discussion, afin de nous faire part de vos avis et commentaire.
            </p>
        </span>
            <div className="link-card-info">
            <span className="link-info">
                <span>
                    <img src={require('../../assets/Picto_Teams_AltyorHub.svg')} alt="Logo"/>
                    <Title style={{fontSize: '1.2em', color: 'white'}}>SAV & FAQ</Title>
                </span>
                <Button
                    onClick={() => {
                        // @ts-ignore
                        window.location = urlTeams
                    }}
                    className="button-access">
                    Accéder
                </Button>
            </span>
                <span className="link-info">
            <span>
                <img src={require('../../assets/Picto_Teams_AltyorHub.svg')} alt="Logo"/>
                <Title style={{fontSize: '1.2em', color: 'white'}}>Suggestions</Title>
            </span>
            <Button
                onClick={() => {
                // @ts-ignore
                window.location = urlTeams
            }}
                className="button-access">
                Accéder
            </Button>
            </span>
            </div>
        </Card>
    )
};

