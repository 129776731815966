import React from 'react';
import './Header.scss';
import { Row, Col } from 'antd';
import { Typography } from 'antd';
import {useHistory} from 'react-router-dom';

const { Title } = Typography;


export const Header: React.FC = () => {
    const history = useHistory()
    return (
        <Row className="header-home">
            <Col span={12} className="header-logo">
                <img
                    onClick={() => history.push('/')}
                    className="logo-altyor"
                    src={require('../../assets/logo_altyor.png')}
                    alt="Logo-Altyor"
                />
            </Col>
            <Col span={12} className="header-title">
                <img className="logo-hub" src={require('../../assets/Logo_AltyorHub.svg')} alt="Logo-hub"/>
                <Title className="title-container">H<em>ub</em></Title>
            </Col>
        </Row>
    )
};
